import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Component } from 'react';

import { NotFound } from './pages/notfound/NotFound';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Database } from './pages/database/Database';
import { Commands } from './pages/commands/Commands';
import { Callback } from './pages/callback/Callback';
import { Logout } from './pages/logout/Logout';
import { Login } from './pages/login/Login';

import { TTheme } from './types/theme';
import { apiUrl } from './config';
import './assets/scss/App.scss';

export default class App extends Component {
    state: { theme: TTheme } = {
        theme: JSON.parse(localStorage.getItem('rushia') ?? '{ "darkTheme": "123" }')?.darkTheme ? 'dark' : 'light'
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' />
                    <Route path='/login' element={ <Login /> } />
                    <Route path='/logout' element={ <Logout /> } />
                    <Route path='/callback' element={ <Callback /> } />
                    <Route path='/commands' element={ <Commands /> } />
                    { /*<Route path='/database' element={ <Database /> } />*/ }
                    <Route path='/dashboard' element={ <Dashboard theme={this.state.theme} /> } />
                    <Route path='*' element={ <NotFound /> } />
                </Routes>
            </BrowserRouter>
        )
    }

    async componentDidMount() {
        document.documentElement.dataset.theme = this.state.theme
        
        if(
            !JSON.parse(localStorage.getItem('rushia') ?? '{}')?.currentUser &&
            window.location.pathname !== '/login' &&
            window.location.pathname !== '/callback' &&
            window.location.pathname !== '/test'
        ) {
            await this.buildConfig(false)
            window.location.href = '/login'
        } else if(window.location.pathname === '/dashboard') {
            await this.buildConfig(true)
        } else {
            await this.buildConfig(false)
            if(window.location.pathname === '/') {
                setTimeout(() => {
                    window.location.href = '/dashboard'
                }, 1000)
            }
        }
    }

    async buildConfig(checkAccount: boolean) {
        if(localStorage.getItem('react')) {
            localStorage.removeItem('react')
        }

        const item = localStorage.getItem('rushia')
        if(!item) {
            localStorage.setItem(
                'rushia',
                JSON.stringify(
                    {
                        cookieAccepted: false,
                        darkTheme: true,
                        locale: 'ru',
                        currentUser: null
                    }
                )
            )
        } else if(checkAccount) {
            const json = JSON.parse(item)
            if(!json?.currentUser?.token) return

            const response = await fetch(`${apiUrl}/private/user`, {
                headers: {
                    'Authorization': json.currentUser.token
                }
            }).then(async (res) => await res.json()).catch(() => null)
            
            if(!response?.ok) {
                json.currentUser = null
                localStorage.setItem('rushia', JSON.stringify(json))
            } else if(response?.result) {
                json.currentUser = response.result
                localStorage.setItem('rushia', JSON.stringify(json))
            }
        }
    }
}